import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PartialContainer } from './primitives/PartialContainer'
import { Pane, TextInputField } from 'evergreen-ui'
import { Alert } from 'react-bootstrap'
import { Title } from './primitives/Title'
import { ActionButton } from './primitives/ActionButton'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import { withFirebase } from './Firebase'
import * as ROUTES from '../constants/routes'
import { navigate } from 'gatsby'

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-left: 10%;
	height: 100%;

	p {
		margin: 0;
	}

	.input {
		background: #FFFFFF;
		width: 330px;
		height: 50px;
		/* Middle blue */

		box-sizing: border-box;
		box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
	}

	.input:focus {
		border: 2px solid #6564DB;
	}

	svg {
		z-index: 99;
		color: lightgray;
	}

	svg:hover {
		cursor: pointer;
	}
`

const Footer = styled.div`
	margin-bottom: 25px;
	
	a {
		color: #6564DB;
	}
	a:hover {
		color: #6564DB;
		text-decoration: none;
	}
`

const LoginForm = (props) => {

	const [showPassword, setShowPassword] = useState(false)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [validationMessage, setValidationMessage] = useState(null)

	const login = (e) => {
		e.preventDefault()

		if (email.length === 0 || password.length === 0) {
			setValidationMessage('email address and password are both required')
			return
		}

		props.firebase
			.doSignInWithEmailAndPassword(email, password)
			.then(() => {
				navigate("/dashboard/galleries")
			})
			.catch(error => {
				if (error.code === 'auth/wrong-password') {
					setValidationMessage('email address and password do not match')
					return
				} else {
					setValidationMessage(error.message)
				}
			})
	}

	return (
		<PartialContainer width={60} backgroundColor="#e5e5e5">
			<FormContainer>
				<h4 style={{ marginBottom: "50px", cursor: "pointer" }} onClick={() => navigate("/")}><i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; Go Back</h4>
				<Title flexGrow={0} marginBottom={70}><i className="fa fa-sign-in" aria-hidden="true"></i> &nbsp; Sign in to PhrameBooth</Title>

				<form onSubmit={login}>
					<small>Email</small>
					<TextInputField
						className="input"
						label=""
						name="email"
						placeholder="Email"
						value={email}
						onChange={e => setEmail(e.target.value)}
					/>
					<br />
					<small>Password</small>
					<Pane display="flex" >
						<TextInputField
							className="input"
							label=""
							name="password"
							placeholder="Password"
							type={showPassword ? 'text' : 'password'}
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
						<Pane display="flex" alignSelf="center" marginLeft={-35} marginTop={-16}>
							{
								showPassword ?
									<AiOutlineEye onClick={() => setShowPassword(false)} /> : <AiOutlineEyeInvisible onClick={() => setShowPassword(true)} />
							}
						</Pane>
					</Pane>
					{
						validationMessage &&
						<Alert variant="danger" style={{ maxWidth: "330px", marginTop: "20px" }}>
							{validationMessage}
						</Alert>
					}
					<ActionButton backgroundColor="#F06878" width={210} marginTop={16} marginBottom={16} type="submit">
						Sign In
					</ActionButton>
				</form>

				<Footer>
					<small>
						{/* <a href="">Forgot Password?</a> */}
					</small>
				</Footer>
			</FormContainer>
		</PartialContainer>
	)
}

LoginForm.propTypes = {
	firebase: PropTypes.any
}

export default withFirebase(LoginForm)
