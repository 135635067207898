import styled from 'styled-components'
const bg = require('../../assets/images/heroImage.jpg')

export const PartialContainer = styled.div`
  background-image: 
    linear-gradient(
      ${props => props.backgroundColor ? props.backgroundColor : 'rgba(54, 33, 101, 0.8)' },
      ${props => props.backgroundColor ? props.backgroundColor : 'rgba(54, 33, 101, 0.8)' }
    ),
    url(${props => (props.bg ? props.bg : bg)});
  background-size: cover;
  background-position: center;
  // width: 100vw;
  height: 50vh;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    width: ${props => props.width ? props.width + 'vw' : '50vw' };;
    height: 100vh;
	}
`