import styled from 'styled-components'

export const Title = styled.h1`
  display: flex;
  flex-basis: ${props => (props.flexBasis ? props.flexBasis : 'auto')};;
  font-weight: 600;
  flex-grow: ${props => (props.flexGrow ? props.flexGrow : '0')};
  font-size: 20px;
  line-height: 123.3%;

  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;

  /* Main pink */
  color: ${props => (props.color ? props.color : '#362165')};

  @media (min-width: 768px) { 
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom + 'px' : '0')};
    flex-basis: auto;
    font-size: 35px;
    line-height: 123.3%;
    /* or 43px */
  }

`