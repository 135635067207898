import React from 'react'

import SEO from '../components/seo'
import LoginForm from '../components/LoginForm'
import { PartialContainer } from '../components/primitives/PartialContainer'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
		flex-direction: row;
	}
`

const LoginPage = () => {	
	
	return (
		<>
			<SEO title="Login" />
			<Container>
				<LoginForm/>
				<PartialContainer />
			</Container>
		</>
	)
}

export default LoginPage
