import styled from 'styled-components'

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
	/* margin: 1rem 0;
  margin-right: 30px; */
  margin-top: ${props => props.marginTop ? props.marginTop+'px' : 0};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom+'px' : 0};
  margin-left: ${props => props.marginLeft ? props.marginLeft+'px' : 0};
  margin-right: ${props => props.marginRight ? props.marginRight+'px' : 0};
  background-color: ${props => props.backgroundColor && !props.border ? props.backgroundColor : 'transparent'};
  color: ${props => props.color ? props.color : props.theme.background};
  outline: none;
  border: ${props => props.backgroundColor && props.border ? '1px solid ' + props.backgroundColor : 'none'};
  border-radius: 5px;
  transition: border 0.1s;
  padding-top: ${props => props.paddingTop ? props.paddingTop+'px' : '0.3rem'};
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom+'px' : '0.3rem'};
  padding-left: ${props => props.paddingLeft ? props.paddingLeft+'px' : '2rem'};
  padding-right: ${props => props.paddingRight ? props.paddingRight+'px' : '2rem'};
  font-weight: 500;
  font-size: ${props => props.fontSize ? props.fontSize+'px' : 'auto'};
  transition: transform 0.5s;
  width: ${props => props.width ? props.width+'px' : 'auto'};
  height: ${props => props.height ? props.height+'px' : '55px'};
  font-family: Poppins;
 
  &:hover {
    cursor: pointer;
  }
  
  a {
    color: #fff;
  }

  a:hover {
    color: #fff;
    text-decoration: none;
  }
  h3 {
    margin-bottom: 0px;
  }
`

export const ActionInput = styled.label`

  display: flex;
  justify-content: center;
  align-items: center;
	/* margin: 1rem 0;
  margin-right: 30px; */
  margin-top: ${props => props.marginTop ? props.marginTop+'px' : 0};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom+'px' : 0};
  margin-left: ${props => props.marginLeft ? props.marginLeft+'px' : 0};
  margin-right: ${props => props.marginRight ? props.marginRight+'px' : 0};
  background-color: ${props => props.backgroundColor && !props.border ? props.backgroundColor : 'transparent'};
  color: ${props => props.color ? props.color : props.theme.background};
  outline: none;
  border: ${props => props.backgroundColor && props.border ? '1px solid ' + props.backgroundColor : 'none'};
  border-radius: 5px;
  transition: border 0.1s;
  padding-top: ${props => props.paddingTop ? props.paddingTop+'px' : '0.3rem'};
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom+'px' : '0.3rem'};
  padding-left: ${props => props.paddingLeft ? props.paddingLeft+'px' : '2rem'};
  padding-right: ${props => props.paddingRight ? props.paddingRight+'px' : '2rem'};
  font-weight: 500;
  font-size: ${props => props.fontSize ? props.fontSize+'px' : 'auto'};
  transition: transform 0.5s;
  width: ${props => props.width ? props.width+'px' : 'auto'};
  height: ${props => props.height ? props.height+'px' : '55px'};

 
  &:hover {
    cursor: pointer;
  }

  input[type=file] {
    display: block;
    height: 0;
    width: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
`
